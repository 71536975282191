import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 96px 0px;
  @media (max-width: 991.98px) {
    padding: 56px 0;
  }
  @media (max-width: 767.98px) {
    padding: 40px 0px;
  }
  overflow: hidden;
  .swiper {
    overflow: visible;
    padding: 0px 30px 100px 30px;
    @media (max-width: 767.98px) {
      padding: 0px 15px 100px 15px;
    }
  }

  .swiper-wrapper {
    transform: none;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -30px;
    position: relative;
    z-index: 1;
    .swiper-prev,
    .swiper-next {
      display: none;
      position: relative;
      height: 40px;
      width: 40px;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 992px) {
        display: flex;
      }
      &:hover {
        cursor: pointer;
      }
      svg {
        height: 14px;
        width: 9px;
        path {
          stroke: ${color.gray[900]};
        }
      }
      &.swiper-button-disabled {
        svg {
          path {
            stroke: ${color.copy[300]};
          }
        }
      }
    }
    .swiper-prev {
      margin-right: 50px;
      position: absolute;
      left: 20%;
      @media (max-width: 1200px) {
        left: 10%;
      }
    }
    .swiper-next {
      margin-left: 50px;
      position: absolute;
      right: 20%;
      @media (max-width: 1200px) {
        right: 10%;
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .swiper-slide {
    flex-shrink: unset;
    display: flex;
    align-items: center;
    height: auto;
    min-height: 452px;
    @media (max-width: 767.98px) {
      width: 298px;
      height: auto;
      min-height: 431px;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 32px;
    border-radius: 0px;
    transition: all 0.15s ease-in-out;
    opacity: 1;
    background: unset;
    position: relative;
    margin: 0 8px !important;
    @media screen and (min-width: 576px) {
      width: 32px;
    }
    @media screen and (min-width: 768px) {
      width: 56px;
      margin: 0 16px !important;
    }
    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      top: 16px;
      left: 0;
      z-index: -1;
      background: ${color.copy[300]};
      border-radius: 10px;
      transition: all 0.4s ease-in-out;
      @media screen and (min-width: 768px) {
        height: 2px;
      }
    }
    &:hover {
      &:after {
        background: ${color.oxfordBlue[800]};
      }
    }
    &-active {
      background: unset;
      &:after {
        background: ${color.oxfordBlue[800]};
      }
    }
  }
`

export const Grid = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`

export const CarouselDetails = styled.div`
  max-width: 770px;
  margin: 0 auto;
  @media (max-width: 767.98px) {
    padding: 0 15px;
  }
  @media (max-width: 991.98px) {
    max-width: 506px;
  }
`

export const Eyebrow = styled.p`
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  line-height: 20px;
  color: ${color.primary[700]};
  text-transform: uppercase;
  @media (max-width: 575.98px) {
    font-size: 12px;
    line-height: 18px;
  }
`

export const Heading = styled.h2`
  color: ${color.oxfordBlue[900]} !important;
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 1rem;
  @media (max-width: 767.98px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: 575.98px) {
    font-size: 24px;
    line-height: 32px;
  }
`

export const Subhead = styled.p`
  font-weight: 350;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: ${color.copy[700]};
  margin: 16px auto 64px auto;
  @media (max-width: 767.98px) {
    font-size: 16px;
    line-height: 24px;
  }
`

import React from "react"

import getPublishedDate from "../../../utils/getPublishedDate"
import truncate from "../../../utils/truncate"
import {
  Clickable,
  Category,
  Heading,
  Description,
  Wrapper,
  ImgWrapper,
  Image,
  CategoryWrapper,
  CategoryChip,
  Eyebrow,
} from "../styles/SlideFrame.styled"

const SlideContent = ({
  url,
  src,
  alt,
  category,
  post,
  description,
  ctaText,
  categories,
}) => (
  <Clickable to={url}>
    <ImgWrapper>
      {src?.gatsbyImageData ? (
        <Image image={src.gatsbyImageData} alt={alt} />
      ) : src?.url ? (
        <Image src={src.url} alt={alt} />
      ) : (
        <Image src={src} alt={alt} />
      )}
    </ImgWrapper>
    {category ? (
      <Category>{category}</Category>
    ) : post?.date ? (
      <Category>
        {getPublishedDate(
          typeof post?.date === "string" ? post?.date : post?.date.toString()
        )}
      </Category>
    ) : null}
    {!category && categories && (
      <CategoryWrapper>
        {categories.map((cat) => (
          <CategoryChip key={cat}>{cat}</CategoryChip>
        ))}
      </CategoryWrapper>
    )}
    <Wrapper isListing={post?.isListing}>
      {post?.isListing && post?.eyebrow && (
        <Eyebrow color={post.eyebrowColor}>{post.eyebrow}</Eyebrow>
      )}
      {post?.title && <Heading>{truncate(post?.title, 45)}</Heading>}
      {!post.isListing && description && (
        <Description>{truncate(description, 120)}</Description>
      )}
      {(post?.labelText || ctaText) && (
        <p className="standalone-link">{post.labelText || ctaText}</p>
      )}
    </Wrapper>
  </Clickable>
)

export default SlideContent

import React from "react"
import { Navigation, Pagination, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { Arrow } from "../../utils/IconsList"
import screenResponsive from "../../utils/screenResponsive"

import CarouselGrid from "./components/CarouselGrid"
import SlideFrame from "./components/SlideFrame"
import {
  Section,
  Grid,
  Eyebrow,
  Heading,
  Subhead,
  CarouselDetails,
} from "./styles/Carousel.styled"

const Carousel = ({
  component,
  background,
  compHeading,
  compEyebrow,
  compSubhead,
}) => {
  const bgClass = background?.replace(/ /g, "").toLowerCase()
  const heading = compHeading ? compHeading : component?.heading
  const eyebrow = compEyebrow ? compEyebrow : component?.eyebrow
  const subhead = compSubhead ? compSubhead : component?.subhead
  const posts = component?.posts?.length ? component?.posts : component
  const isTablet = screenResponsive()

  if (posts.length > 3 || isTablet) {
    /* If there are more than 3 posts, the carousel will render */
    return (
      <Section className={`bg-${bgClass}`}>
        <Grid>
          <CarouselDetails>
            <Eyebrow>{eyebrow}</Eyebrow>
            <Heading>{heading}</Heading>
            <Subhead>{subhead}</Subhead>
          </CarouselDetails>
        </Grid>
        <Swiper
          modules={[Navigation, Pagination, Keyboard]}
          spaceBetween={30}
          slidesPerView="auto"
          centeredSlides={true}
          grabCursor
          keyboard
          speed={1000}
          autoplay={{
            delay: 5000,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          breakpoints={{
            320: {
              spaceBetween: 15,
              centeredSlides: true,
            },
            576: {
              spaceBetween: 30,
              centeredSlides: true,
            },
            992: {
              spaceBetween: 30,
              centeredSlides: false,
            },
            1200: {
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
        >
          {posts &&
            posts.map((post, index) => (
              <SwiperSlide key={index}>
                <SlideFrame post={post} type="carousel" />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="nav">
          <div className="swiper-prev">
            <Arrow />
          </div>
          <div className="swiper-next">
            <Arrow />
          </div>
        </div>
      </Section>
    )
  }

  /* If there are 3 or fewer posts, they will render as a grid */
  return (
    <Section className={`bg-${bgClass}`}>
      <Grid>
        <CarouselDetails>
          <Eyebrow>{eyebrow}</Eyebrow>
          <Heading>{heading}</Heading>
          <Subhead>{subhead}</Subhead>
        </CarouselDetails>
      </Grid>
      <CarouselGrid posts={posts} />
    </Section>
  )
}

export default Carousel

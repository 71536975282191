import { Link } from "gatsby"
import styled from "styled-components"

import { color } from "../../../atoms/colors"
import OptimizedImage from "../../../utils/OptimizedImage"

export const Frame = styled.div`
  position: relative;
  height: ${(props) => (props?.isListing ? "auto" : "100%")};
  min-height: ${(props) => (props?.isListing ? "auto" : "452px")};
  width: 370px;
  border-radius: 12px;
  background: ${color.gray[100]};
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    background: ${color.common.white};
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  }
  @media (max-width: 767.98px) {
    width: 298px;
    min-height: 431px;
  }
`

export const GridFrame = styled.div`
  position: relative;
  height: 100%;
  min-height: ${(props) => (props?.isListing ? "auto" : "452px")};
  max-width: 370px;
  border-radius: 12px;
  background: ${color.daveysGray[25]};
  transition: 0.3s ease;
  &:hover {
    cursor: pointer;
    background: ${color.common.white};
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  }
  @media (max-width: 767.98px) {
    width: 298px;
    min-height: 431px;
  }
`

export const Clickable = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  &:hover {
    text-decoration: none;
    h3 {
      color: ${color.common.linkBlue};
    }
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 208px;
  min-height: 208px;
  overflow: hidden;
  @media (max-width: 767.98px) {
    max-height: 167px;
    min-height: 167px;
  }
`

export const Image = styled(OptimizedImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`

export const Category = styled.p`
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${color.primary[100]};
  border-radius: 16px;
  color: ${color.common.linkBlue};
  overflow: hidden;
  white-space: nowrap;
`

export const CategoryChip = styled.p`
  padding: 4px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background: ${color.primary[100]};
  border-radius: 16px;
  color: ${color.common.linkBlue};
  white-space: nowrap;
  margin: 0;
`

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  top: 24px;
  left: 24px;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
`

export const Wrapper = styled.div`
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props?.isListing ? "30px" : "")};
  .standalone-link {
    color: ${color.common.linkBlue};
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: auto;
  }
`

export const Eyebrow = styled.span`
  color: ${(props) => (props?.color ? props.color : "inherit")};
  font-family: Avenir, sans-serif;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: -20px;
`

export const Heading = styled.h3`
  font-weight: 900;
  font-size: 20px;
  line-height: 30px;
  color: ${color.oxfordBlue[900]};
  text-decoration: none;
  margin-bottom: 10px;
  transition: 0.3s ease;
  @media (max-width: 767.98px) {
    font-size: 18px;
    line-height: 28px;
  }
`

export const Description = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${color.copy[700]};
  text-decoration: none;
  margin-bottom: 30px;
`

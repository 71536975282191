export const getPostUrl = (post) => {
  const internalType = post?.internal?.type
  const postType = post?.type
  const cslug = post?.cslug ? post?.cslug : post?.category?.slug

  if (internalType === "DatoCmsTemplateBlogPost" || postType === "Blogs") {
    return `/blog/${post?.slug}`
  } else if (
    internalType === "DatoCmsTemplateArticle" ||
    postType === "Articles"
  ) {
    return `/articles/${cslug}/${post?.slug}`
  } else if (internalType === "DatoCmsTemplateEbook" || postType === "eBooks") {
    return `/ebooks/${cslug}/${post?.slug}`
  } else if (
    internalType === "DatoCmsTemplateInfographic" ||
    postType === "Infographics"
  ) {
    return `/infographic/${cslug}/${post?.slug}`
  } else if (internalType === "DatoCmsTemplateVideo" || postType === "Videos") {
    return `/videos/${cslug}/${post?.slug}`
  } else if (
    internalType === "DatoCmsTemplateWebinar" ||
    postType === "Webinars"
  ) {
    return `/webinars/${cslug}/${post?.slug}`
  } else if (
    internalType === "DatoCmsTemplateWhitePaper" ||
    postType === "Whitepapers" ||
    postType === "White Papers"
  ) {
    return `/whitepapers/${cslug}/${post?.slug}`
  } else if (
    internalType === "DatoCmsTemplateCaseStudy" ||
    postType === "CaseStudies"
  ) {
    return `/case-studies/${post.slug}`
  } else if (postType === "Demo") {
    return `/democenter/${post.slug}`
  } else {
    return `/press-center/${post?.slug}`
  }
}

import React from "react"

import { GridContainer, CardWrapper } from "../styles/CarouselGrid.styled"

import SlideFrame from "./SlideFrame"

const CarouselGrid = ({ posts }) => (
  <GridContainer>
    {posts &&
      posts.map((post, index) => (
        <CardWrapper key={index}>
          <SlideFrame post={post} type={"grid"} />
        </CardWrapper>
      ))}
  </GridContainer>
)

export default CarouselGrid

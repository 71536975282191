import React from "react"

import placeholderThumb from "../../../images/placeholder-thumbnail.png"
import { getPostUrl } from "../../../utils/getPostUrl"
import { Frame, GridFrame } from "../styles/SlideFrame.styled"

import SlideContent from "./SlideContent"

const SlideFrame = ({ post, type }) => {
  const url = getPostUrl(post)

  let ctaText
  switch (post?.internal?.type) {
    case "DatoCmsTemplateBlogPost":
      ctaText = "Read Blog"
      break
    case "DatoCmsTemplateArticle":
      ctaText = "Read Article"
      break
    case "DatoCmsTemplateEbook":
      ctaText = "Read eBook"
      break
    case "DatoCmsTemplateInfographic":
      ctaText = "Read Infographic"
      break
    case "DatoCmsTemplateWebinar":
      ctaText = "Watch Webinar"
      break
    case "DatoCmsTemplateWhitePaper":
      ctaText = "Read White Paper"
      break
    case "DatoCmsTemplateVideo":
      ctaText = "Watch Video"
      break
    default:
      null
  }

  const src = post?.featuredImage
    ? post?.featuredImage
    : post?.seo?.image
    ? post?.seo?.image.url
    : post?.img

  const alt = `${post?.type} illustration image`

  const category = post?.category?.name ? post?.category?.name : post?.category
  const categories =
    post?.categories?.length > 0 ? post.categories.map((cat) => cat.name) : []

  const description = post?.shortDesc
    ? post?.shortDesc
    : post?.desc
    ? post?.desc
    : post?.description
    ? post?.description
    : post?.seo?.description
    ? post?.seo?.description
    : post?.body?.value?.document?.children[0]?.children[0]?.value

  if (type === "grid") {
    return (
      <GridFrame isListing={post?.isListing}>
        <SlideContent
          url={url}
          alt={alt}
          src={src ? src : placeholderThumb}
          category={category}
          categories={categories}
          post={post}
          description={description}
          ctaText={ctaText}
        />
      </GridFrame>
    )
  }

  return (
    <Frame isListing={post?.isListing}>
      <SlideContent
        url={url}
        alt={alt}
        src={src ? src : placeholderThumb}
        category={category}
        categories={categories}
        post={post}
        description={description}
        ctaText={ctaText}
      />
    </Frame>
  )
}

export default SlideFrame

const getPublishedDate = (date) => {
  if (typeof date === "string") {
    const d = new Date(date.replace(/-/g, "/"))
    return (
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
      " " +
      d.toLocaleString("en-us", { month: "long" }) +
      " " +
      d.getFullYear()
    )
  } else {
    return ""
  }
}

export default getPublishedDate

import styled from "styled-components"

export const GridContainer = styled.div`
  max-width: 1170px;
  @media (max-width: 991.98px) {
    max-width: 770px;
  }
  @media (max-width: 767.98px) {
    max-width: 506px;
  }
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

export const CardWrapper = styled.div`
  margin: 0 15px;
  width: 100%;
  max-width: 370px;
`
